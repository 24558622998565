export const MAIN_PAGE = '/'  // 메인페이지
export const MAIN_PAGE_TEMP = '/main'  // 메인페이지
export const LOGIN_PAGE = '/login' // 로그인
export const SIGNUP_PAGE = '/signup' // 회원가입
export const UNREGISTER_PAGE = '/unregister' // 회원가입
export const MY_CART_PAGE = '/my_cart'  // 카트보기
export const REQUEST_LIST_PAGE = '/request_list'  // 의뢰내역
export const MY_INFO_PAGE = '/my_info' // 마이페이지
export const MY_STORE_LIST_PAGE = '/my_store' // 내 즐겨찾기 지점리스트
export const MY_SETTING_PAGE = '/my_setting' // 내 즐겨찾기 지점리스트
export const BOARD_LIST_PAGE = '/board_list' // 게시판 - 리스트
export const BOARD_WRITE_PAGE = '/board_write'  // 게시판 - 작성 페이지
export const BOARD_DETAIL_PAGE = '/board_detail' // (+boardId) 게시판 - 상세보기
export const FAQ_PAGE = '/faq' // 자주 묻는 질문

// <-- 지점 페이지 -->
export const STORE_REQUEST_LIST_PAGE = '/store_request_list'  // 지점 의뢰내역
export const MY_STORE_INFO_PAGE = '/my_store_info' // 내 지점 관리
export const STAFF_MANAGEMENT_PAGE = '/staff_management' // 직원 관리
export const STORE_CUSTOMER_MANAGEMENT_PAGE = '/store_customer_management' // 고객관리
export const STORE_REPORT_PAGE = '/store_report' // 고객가계부

// <-- 관리자 페이지 -->
export const ADMIN_REPORT_PAGE = '/admin_report' // 집계 페이지
export const GAME_MANAGEMENT_PAGE = '/game_management' // 경기 관리 (관리자 페이지)
export const ADMIN_REQUEST_MANAGEMENT_PAGE = '/admin_request_management' // 의뢰 관리 (관리자 페이지)
export const ALL_STORE_LIST_MANAGEMENT_PAGE = '/all_store_list_management' // 전체 지점 리스트
export const STORE_MANAGEMENT_PAGE = '/store_management' // 지점 관리 (관리자 페이지)
export const GAME_ROUND_MANAGEMENT_PAGE = '/game_round_management' // 회차 관리 (관리자 페이지)
export const ALL_CUSTOMER_MANAGEMENT_PAGE = '/all_customer_list_management' // 전체 고객 리스트
export const DEVELOPMENT_HISTORY_PAGE = '/dev_history' // 개발 이력
export const ADMIN_CALCULATE_PAGE = '/admin_calculate' // 정산 페이지
export const MONITORING_STORE_PAGE = '/monitoring_store' // 지점 모니터링 페이지

// <-- 개발자 페이지 -->
export const DEVELOPER_MANAGEMENT_PAGE = '/dev_management'  // 개발 관리 페이지
export const DEVELOPER_MONITORING_PAGE = '/dev_monitoring'  // 개발 모니터링 페이지


export const FORBIDDEN_PAGE = '/forbid_page' // 접근제한 페이지
export const EXCEL_LOAD_PAGE = "/excel_load_page" // 엑셀 띄우는 페이지
