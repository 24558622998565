import neverLandGameData from "../../utils/NeverLandGameData";
import {useEffect, useState} from "react";
import React from 'react';
import soccerIcon from "../../assets/images/icon_soccer.png"
import baseballIcon from "../../assets/images/icon_baseball.png"
import volleyballIcon from "../../assets/images/icon_volleyball.png"
import basketballIcon from "../../assets/images/icon_basketball.png"
import neverLandUtils from "../../utils/NeverLandUtils";
import {toast} from "react-toastify";
import {accountInfo, getLogoImage} from "../../constants/constants";
import {RiLock2Fill, RiLockFill} from "react-icons/ri";
import lockIcon from '../../assets/images/lock_icon.png';

export const getGameLogoImgURL = (category) => {
  if (category === 'SC') return soccerIcon;
  if (category === 'BK') return basketballIcon;
  if (category === 'VL') return volleyballIcon;
  if (category === 'BS') return baseballIcon;
}

const GameCard = ({setMouseOverData}) => {
  const [index, setIndex] = useState(0);
  const updateUI = () => {
    setIndex(prevState => prevState + 1);
  }
  const handleOnChanged = () => {
    updateUI();
  };

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    neverLandGameData.loadGameDataList("game_card");
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
    }
  }, [])

  const _toggleSelectItem = (parentGameInfo, thisGameData, id, key) => {
    // 조건부로 버튼 안먹히게
    if (parentGameInfo.state !== "발매중")
      return;
    if (key === "d" && ((thisGameData.extraText !== 'H' || thisGameData.draw === '0.00') && thisGameData.extraText !== '무'
        && thisGameData.extraText !== '⑤'
        && thisGameData.extraText !== '①'))
      return;
    if (neverLandGameData.toggleSelectItem(id, key)) {
      return;
    }
    toast.warn('10경기 이상 선택할 수 없습니다.');
  }

  function isLockState(buttonData, selection) {
    // 버튼 잠금 여부 결정
    let selectionFlag = 0;
    // 배당이 1.0일때 버튼 잠그기
    if (selection === 'w') {
      if (buttonData.win === '1.00') {
        return true;
      }
      selectionFlag = 1 << 0;
    }
    if (selection === 'd') {
      if (buttonData.draw === '1.00') {
        return true;
      }
      selectionFlag = 1 << 1;
    }
    if (selection === 'l') {
      if (buttonData.lose === '1.00') {
        return true;
      }
      selectionFlag = 1 << 2;
    }

    let lockState = buttonData.lockState !== null ? parseInt(buttonData.lockState, 10) : 0;
    let lockStateByAdmin = buttonData.lockStateByAdmin !== null ? parseInt(buttonData.lockStateByAdmin, 10) : 0;

    if (lockState & selectionFlag) {
      return true;
    }
    if (lockStateByAdmin & selectionFlag) {
      return true;
    }
    return false;
  }

  let use_magnifier_mode = accountInfo.getUserConfig().isOn('use_magnifier_mode', false);
  return (
      <>
        <div>
          {/*<ul role="list"*/}
          {/*    className="grid grid-cols-1 gap-6 md:grid-cols-1 xl:grid-cols-2 xl-only:grid-cols-3 2xl-only:grid-cols-4">*/}
          <ul role="list"
              className="flex flex-wrap justify-center items-center gap-3">
            {neverLandGameData.getFilteredGameData()?.map((game, index) => {
              // 데이터 유효성 검사
              if (game.closingDate === "-") {
                return null;
              }

              return (
                  <React.Fragment key={index}>
                    <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white text-center shadow overflow-hidden max-w-[25rem] min-w-[17rem]">
                      <div className="flex flex-1 flex-row justify-between px-4 py-1.5 ">
                        <div>
                          <div className={"flex flex-row items-center"}>
                            <img src={getGameLogoImgURL(game.category)}
                                 className={neverLandUtils.classNames(game.category === 'BS' ? "h-6 w-6" : "h-5 w-5", "mx-2")}/>
                            <p className="font-semibold">{game.leagueName}</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">{neverLandGameData.formatDate(game.closingDate)}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          {game.state === '50' ? // 게임 잠금
                              <span
                                  className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium bg-red-100 text-red-700">
                              <RiLock2Fill className="h-5 w-auto"/>
                              </span>
                              : <span
                                  className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium 
                              ${game.state === "발매중" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"} `}
                              >
                              {game.state === "null" ? "준비중" : game.state}
                              </span>
                          }
                        </div>
                      </div>

                      <div className="text-sm flex flex-1 flex-row justify-center p-2.5">
                        <table>
                          <thead>
                          <tr className="text-base">
                            <th scope="col" className="w-1/7">{''}</th>
                            <th scope="col" className="w-2/7">{game.homeTeam}</th>
                            <th scope="col" className="text-xl w-2/7">vs</th>
                            <th scope="col" className="w-2/7">{game.awayTeam}</th>
                          </tr>
                          </thead>

                          <tbody>
                          {game.data?.map((buttonData) => {
                            if (neverLandGameData.isFilteredGameAllotData(buttonData.extraText)) {
                              return null;
                            }
                            if (game.state === "null") return null;
                            return (
                                <tr key={buttonData.allotId}>
                                  {/* <- 경기번호 버튼 -> */}
                                  <td>
                                    <div
                                        className="h-10 w-10 border border-gray-300 rounded-full px-6 sm:px-4 mt-1 flex items-center justify-center">
                                      <p>{buttonData.gameNo.replace("ⓢ", "")}</p>
                                      <p className="text-orange-600 font-semibold">{buttonData.gameNo.includes("ⓢ") ? "ⓢ" : ""}</p>
                                    </div>
                                  </td>

                                  {/* <- 승 버튼 -> */}
                                  <td className="w-full"
                                      onMouseOver={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData({
                                            displayTitle: game.homeTeam + " vs " + game.awayTeam,
                                            dataType: 'win',
                                            displayData: buttonData
                                          });
                                      }}
                                      onMouseLeave={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData(null);
                                      }}
                                  >
                                    <div className="flex w-full">
                                      {buttonData.win === "0.00" ?
                                          <div
                                              className='border-green-100 text-purple-300 h-12 w-full sm:w-16 ml-1 border rounded-lg px-5 mt-1 flex items-center justify-center'>
                                            -
                                          </div>
                                          :
                                          <button
                                              disabled={isLockState(buttonData, 'w')}
                                              onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'w')}
                                              className="flex-1"
                                              // 잠금상태면 자물쇠 배경
                                              style={isLockState(buttonData, 'w') ? {
                                                background: `url(${lockIcon})`,
                                                backgroundSize: '55%',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat'
                                              } : {}}
                                          >
                                            <div
                                                className={neverLandUtils.classNames(
                                                    isLockState(buttonData, 'w') ? 'bg-gray-200 bg-opacity-50 cursor-not-allowed' :
                                                        neverLandGameData.isSelected(buttonData.allotId, 'w') ? 'bg-neutral-400 border-neutral-400' : '',
                                                    'h-12 w-full sm:w-16 ml-1 border border-gray-300 rounded-lg px-5 mt-1 flex items-center justify-center'
                                                )}
                                            >
                                              {buttonData.extraText === "U/O" ? "U" : buttonData.extraText === "SUM" ? "홀" : "승"}
                                              <br/> {buttonData.win}
                                            </div>
                                          </button>
                                      }
                                    </div>
                                  </td>

                                  {/* <- 무 버튼 -> */}
                                  <td className="w-full"
                                      onMouseOver={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData({
                                            displayTitle: game.homeTeam + " vs " + game.awayTeam,
                                            displayData: buttonData,
                                            dataType: 'draw'
                                          });
                                      }}
                                      onMouseLeave={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData(null);
                                      }
                                      }
                                  >
                                    <div className="flex w-full">
                                      {(parseFloat(buttonData.draw) === 0 && buttonData.extra === '0.0' && buttonData.extraText !== 'SUM' || buttonData.extraText === null) ?
                                          // 무 배당이 0.00 이면 버튼 - 으로 표시
                                          <div
                                              className='border-sky-100 text-blue-300 h-12 w-full sm:w-16 ml-1 border rounded-lg px-5 mt-1 flex items-center justify-center'>
                                            -
                                          </div>
                                          :
                                          ((buttonData.extraText == null || buttonData.extraText === "null") ? "-" :
                                                  <button
                                                      disabled={isLockState(buttonData, 'd')}
                                                      onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'd')}
                                                      className="flex-1"
                                                      style={isLockState(buttonData, 'd') ? {
                                                        background: `url(${lockIcon})`,
                                                        backgroundSize: '55%',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat'
                                                      } : {}}
                                                  >
                                                    <div className={neverLandUtils.classNames(
                                                        isLockState(buttonData, 'd') ? 'bg-gray-200 bg-opacity-50 cursor-not-allowed' :
                                                            neverLandGameData.isSelected(buttonData.allotId, 'd') ? 'bg-neutral-400 border-neutral-400' : '',
                                                        ((buttonData.extraText !== 'H' || parseFloat(buttonData.draw) === 0) && buttonData.extraText !== '무'
                                                            && buttonData.extraText !== '⑤'
                                                            && buttonData.extraText !== '①') ?
                                                            'cursor-not-allowed text-sky-800 border-sky-300 bg-sky-100' : 'border-gray-300',
                                                        'h-12 w-full sm:w-16 ml-1 border rounded-lg px-5 mt-1 flex items-center justify-center'
                                                    )}>
                                                      {
                                                        // 무, 승일패(①), 승오패(⑤)인 경우
                                                        (buttonData.extraText === '무' || buttonData.extraText === '①' || buttonData.extraText === '⑤') ?
                                                          <p>{buttonData.extraText}<br/> {buttonData.draw}</p>

                                                          // 핸디면서 배당이 0이 아닐때
                                                          : buttonData.extraText === 'H' && parseFloat(buttonData.draw) !== 0  ?
                                                              <p className="text-xs whitespace-nowrap">{"무 " + buttonData.draw}<br/>
                                                                {buttonData.extraText + (buttonData.extra > 0 ? "+" : " ") + buttonData.extra}
                                                              </p>

                                                              // SUM 일때
                                                              : buttonData.extraText === 'SUM' ?
                                                                  <p className="cursor-not-allowed text-sky-800 border-sky-300 bg-sky-100">
                                                                    {buttonData.extraText}
                                                                  </p>

                                                                  : <p>
                                                                    {buttonData.extraText}<br/> {((buttonData.extraText === 'H' && buttonData.extra > 0) ? "+" : "") + buttonData.extra}
                                                                  </p>
                                                      }
                                                    </div>
                                                  </button>
                                          )}
                                    </div>
                                  </td>

                                  {/* <- 패 버튼 -> */}
                                  <td className="w-full"
                                      onMouseOver={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData({
                                            displayTitle: game.homeTeam + " vs " + game.awayTeam,
                                            displayData: buttonData,
                                            dataType: 'lose'
                                          });
                                      }}
                                      onMouseLeave={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData(null);
                                      }}
                                  >
                                    <div className="flex w-full">
                                      {buttonData.lose === "0.00" ?
                                          <div
                                              className='border-orange-100 text-red-300 h-12 w-full sm:w-16 ml-1 border rounded-lg px-5 mt-1 flex items-center justify-center'> - </div> :
                                          <button
                                              disabled={isLockState(buttonData, 'l')}
                                              className="flex-1"
                                              onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'l')}
                                              style={isLockState(buttonData, 'l') ? {
                                                background: `url(${lockIcon})`,
                                                backgroundSize: '55%',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat'
                                              } : {}}
                                          >
                                            <div
                                                className={neverLandUtils.classNames(
                                                    isLockState(buttonData, 'l') ? 'bg-gray-200 bg-opacity-50 cursor-not-allowed' :
                                                        neverLandGameData.isSelected(buttonData.allotId, 'l') ? 'bg-neutral-400 border-neutral-400' : '',
                                                    'h-12 w-full sm:w-16 ml-1 border border-gray-300 rounded-lg px-5 mt-1 flex items-center justify-center'
                                                )}

                                            >{buttonData.extraText === "U/O" ? "O" : buttonData.extraText === "SUM" ? "짝" : "패"}
                                              <br/> {buttonData.lose}
                                            </div>
                                          </button>
                                      }
                                    </div>
                                  </td>
                                </tr>
                            );
                          })}
                          </tbody>
                        </table>
                      </div>
                    </li>
                  </React.Fragment>
              );
            })}
          </ul>
        </div>
      </>
  )
}
export default GameCard;
